<template>
	<table-page
		v-if="columns.length"
		v-show="isLoaded"
		:ref="$options.name"
		:tableName="$options.name"
		:columns="columns"
		:filterModel="filterModel"
		headerTitle="seopages2665"
		tooltipMsg="pmanualforseopa"
		:requestFunction="requestFunction"
		:defaultOrderBy="{ column: 'SpageUrl', ascending: 1 }"
		modelId="SpageId"
		@addNewRecord="redirectToDetail()"
		@editRecord="redirectToDetail($event)"
		@filterChanged="handleFilter($event)"
		@removeFilters="removeAllFilters($event)"
		class="SeoPageTable"
	>
		<template #customActions="list">
			<megau-button
				v-if="showDeleteButton"
				classprop="btn btn-customdanger ml-1"
				icon="trash-alt"
				:tooltip="$t('delete')"
				@handleClick="openDeleteModal(list.row.SpageId)"
			></megau-button>
		</template>

		<template #SpageUrl="list">
			<a :href="list.row.UrlWithDomain" target="_blank">{{ list.row.SpageUrl }}</a>
		</template>

		<template #SpageLanguage="list">
			<div v-text="list.row.SpageLanguage.toLowerCase()"></div>
		</template>

		<template #FirstArticleCreatedDate="list">{{ list.row.FirstArticleCreatedDate | dateTime }}</template>

		<template #FirstArticleVerifiedDate="list">{{ list.row.FirstArticleVerifiedDate | dateTime }}</template>

		<template #SecondArticleCreatedDate="list">{{ list.row.SecondArticleCreatedDate | dateTime }}</template>

		<template #SecondArticleVerifiedDate="list">{{ list.row.SecondArticleVerifiedDate | dateTime }}</template>

		<template #SpgroupId="list">{{ formatGroup(list.row.SpgroupId) }}</template>
	</table-page>
</template>

<script>
import TablePage from '@/components/general/table-page';
import { tablePageMixin } from '@/components/general/table-page.mixin';
import { setDropdownOptions } from '@/components/general/utils';
import * as links from '@/router/links';
import serviceCommon from '@/services/service/common.service';
import serviceDictionary from '@/services/service/dictionary.service';
import auth from '@/services/auth.service';
import enums from '@/services/helpers/enums';

import { tableColumns } from './seo-page.table-data';

export default {
	name: 'SeoPageTable',

	components: {
		TablePage,
	},

	mixins: [tablePageMixin],

	data() {
		return {
			controllerName: 'SeoPageListItem',
			groupDict: null,
		};
	},

	computed: {
		showDeleteButton() {
			return auth.IsAllowedForUser(enums.role.Admin);
		},
	},

	async created() {
		this.columns = await this.prepareColumns(tableColumns);
	},

	methods: {
		redirectToDetail(SeoPageId = 0) {
			this.$router.push({
				name: links.seoPageDetailName,
				params: { SeoPageId },
			});
		},

		async prepareColumns(columns) {
			const [seoGroupEnum] = await Promise.all([serviceDictionary.getSeoGroupEnum()]);

			let cols = prepareSpgroupId.bind(this)(columns);
			cols = prepareSpageLanguage.bind(this)(columns);

			return cols;

			function prepareSpgroupId(columns) {
				const values = seoGroupEnum.seoGroupSelectorItems;

				this.groupDict = seoGroupEnum.seoGroupDict;

				return setDropdownOptions(columns, 'SpgroupId', values);
			}

			function prepareSpageLanguage(columns) {
				const values = this.$store.getters.countryLanguageCodeForDropdown;

				return setDropdownOptions(columns, 'SpageLanguage', values);
			}
		},

		formatGroup(groupId) {
			if (this.groupDict) {
				return this.groupDict[groupId - 1].Text;
			} else {
				return '';
			}
		},

		openDeleteModal(itemId) {
			serviceCommon.openDeleteModal(itemId, this.$modal, this.deleteThisItem);
		},
		deleteThisItem(id) {
			serviceCommon.deleteItem(id, this.tableGetData, this.controllerName);
		},
	},
};
</script>
<style lang="scss">
.SeoPageTable {
	.VueTables__filters-row > th:nth-child(1) {
		min-width: 55px;
		width: 55px;
	}
	.VueTables__filters-row > th:nth-child(8) {
		max-width: 100px;
	}
}
</style>