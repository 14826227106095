import i18n from '@/services/lang';

export const tableColumns = [
	{
		model: 'SpageUrl',
		i18n: 'url',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'SpageCaptionH1',
		i18n: 'htitle',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'SpageMetaTitle',
		i18n: 'metatitle',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'SpageLanguage',
		i18n: 'language',
		sortable: true,
		filter: 'dropdown',
	},
	{
		colType: 'country',
		model: 'CountryId',
		i18n: 'country',
		sortable: true,
	},
	{
		model: 'SpgroupId',
		i18n: 'group',
		sortable: true,
		filter: 'dropdown',
	},
	{
		model: 'FirstArticleCreatedDate',
		i18n: 'created3406',
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'FirstArticleCreatedBy',
		i18n: 'whocreated3405',
		sortable: true,
		filter: 'text',
	},
	{
		model: 'FirstArticleVerifiedDate',
		i18n: 'checked3404',
		hidden: true,
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'FirstArticleVerifiedBy',
		i18n: 'whochecked',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'SecondArticleCreatedDate',
		i18n: 'created3408',
		hidden: true,
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'SecondArticleCreatedBy',
		i18n: 'whocreated3409',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
	{
		model: 'SecondArticleVerifiedDate',
		i18n: 'checked3410',
		hidden: true,
		sortable: true,
		filter: 'daterange',
	},
	{
		model: 'SecondArticleVerifiedBy',
		i18n: 'whochecked3411',
		hidden: true,
		sortable: true,
		filter: 'text',
	},
];
